import axios from 'axios';
import { USER_KEY, APP_STYLE, SITE_VARIANTS } from '../constants';
import { getProperty } from '../core/Utils/objectUtils';
import { ensureTrailingSlash } from './stringUtils';

import {
  URL_MAP,
  getURL,
  getDeleteReportURL,
  getReportListURL,
  getAPIsURL,
  getAuthURL,
  getCanEditURL,
  getAddAPIFormsURL,
  getAddAPIQuestionsURL,
  getAddAPICreateURL,
  getAPISemanticSearchURL,
  getSTypesURL,
  getCatsURL,
  getDateRangeURL,
  getRecipReplaceURL,
  getHMDAStateFormURL,
  getHMDAStateQueryURL,
  getHMDAAggregateFormURL,
  getHMDAAggregateQueryURL,
  getHMDAAggregateFieldsURL,
  getHMDAColumnInfoURL,
  getDataSrcFormURL,
  getCollectionURL,
  getReportDestinationsURL,
  getDataSourceFieldsURL,
  getUSStateReportURL,
  getUSStateReportFieldsURL,
  getUSStateRetrieveURL,
  getUSStateFieldsURL,
  getAddGlossaryEntryURL,
  getAddBibliographyEntryURL,
  getAddJournalPeopleEntryURL,
  getJournalPeopleReadURL,
  getSecurityManagerURL,
  getIsValidUserURL,
  getJournalTextURL,
  getJournalSubmissionFormURL,
  getJournalSubmissionURL,
  getJournalManuscriptsMapURL,
  getJournalManuscriptsActionDoURL,
  getJournalManuscriptsActionReadURL,
  getJournalDashColumnsURL,
  getJournalStatesReadURL,
  getJournalMastheadURL,
} from './urlMapUtils';

export function registerAuthKey(key) {
  /* Did this to stop crashes when axios is mocked, but I'm not sure this is a good
   * approach: a return rather than an error message might be hard to debug, as it'll
   * be a little unclear why any errors happen down the line. Will maybe think of
   * something better later :) - Sam
   * */
  if (!axios) return;
  if (!axios.defaults) return;
  if (!axios.defaults.headers) return;
  if (!axios.defaults.headers.common) return;
  axios.defaults.headers.common.Authorization = key;
}

async function checkAuth(user) {
  const params = {
    headers: { [USER_KEY]: user[USER_KEY] },
    params: { user_id: localStorage.getItem('userID') },
  };
  return new Promise((resolve, reject) => {
    axios.get(getAuthURL(), params)
      .then((resp) => { resolve(resp); })
      .catch((err) => { reject(err); });
  });
}

async function deleteReport(reportID) {
  let url = getDeleteReportURL();
  url = ensureTrailingSlash(url);
  url = url.concat(reportID);
  return new Promise((resolve, reject) => {
    axios.delete(url)
      .then((resp) => { resolve(resp); })
      .catch((err) => { reject(err); });
  });
}

async function fetchReportList(userID) {
  let url = getReportListURL();
  url = ensureTrailingSlash(url);
  url = url.concat(userID);
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then((resp) => { resolve(resp); })
      .catch((err) => { reject(err); });
  });
}

async function getDataSourceFields() {
  const url = getDataSourceFieldsURL();
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Data source fields']); })
      .catch((err) => { reject(err); });
  });
}

async function storeDataSources(dataSources) {
  const usedFields = await getDataSourceFields();
  const usedFieldKeys = Object.keys(usedFields);
  const strippedSources = {};
  Object.keys(dataSources).forEach((dataSourceID) => {
    const dataSource = dataSources[dataSourceID];
    const strippedSource = {};
    Object.keys(dataSource).forEach((property) => {
      if (!usedFieldKeys.includes(property)) return;
      strippedSource[property] = dataSource[property];
    });
    strippedSources[dataSourceID] = strippedSource;
  });
  localStorage.setItem('reportDataSources', JSON.stringify(strippedSources));
  localStorage.setItem('reportDataSourcesTimestamp', new Date());
}

function loadCachedAPIs() {
  try {
    const cachedSources = JSON.parse(localStorage.getItem('reportDataSources'));
    const cacheTimestamp = localStorage.getItem('reportDataSourcesTimestamp');
    const shouldReload = () => {
      try {
        if (typeof cachedSources !== 'object') return true;
        if (Object.keys(cachedSources).length === 0) return true;
        if (!cacheTimestamp) return true;
        const timestampTime = Date(cacheTimestamp).getTime;
        const currentTime = Date.now();
        const difference = currentTime - timestampTime;
        const reloadTime = 7 * 24 * 60 * 60 * 1000;
        return difference >= reloadTime;
      } catch {
        return true;
      }
    };
    return {
      shouldReload,
      cachedSources,
    };
  } catch {
    return {
      shouldReload: () => true,
      cachedSources: {},
    };
  }
}

async function fetchAPIs(cancelToken) {
  try {
    const { shouldReload, cachedSources } = loadCachedAPIs();
    if (!shouldReload()) return Promise.resolve(cachedSources);
  } catch (error) { console.error(error); }

  const url = getAPIsURL();
  return new Promise((resolve, reject) => {
    axios.get(url, { cancelToken })
      .then(({ data }) => {
        resolve(data);
        try {
          storeDataSources(data);
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => { reject(err); });
  });
}

async function canEditReport(userID, reportID) {
  const url = getCanEditURL();
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { user: userID, rpt_id: reportID } })
      .then((resp) => { resolve(resp.status === 200); })
      .catch((err) => { reject(err); });
  });
}

async function getAddDataSourceTypes() {
  const url = getAddAPIFormsURL();
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Add APIs Menu']); })
      .catch((err) => { reject(err); });
  });
}

async function getAddDataSourceQuestions(sourceType) {
  if (typeof sourceType !== 'string') {
    throw new Error('getAddDataSourceQuestions expected sourceType to be a string');
  }
  const url = `${getAddAPIQuestionsURL()}/${sourceType}`;
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Add API form']); })
      .catch((err) => { reject(err); });
  });
}

async function getFrequencyOptions(dataSourceIDs) {
  const url = getSTypesURL(dataSourceIDs);
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['SType Map']); })
      .catch((err) => { reject(err); });
  });
}

async function addDataSource(sourceType, details) {
  const url = `${getAddAPICreateURL()}/${sourceType}`;
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then((resp) => { resolve(resp); })
      .catch((err) => { reject(err); });
  });
}

async function APISemanticSearch(query) {
  const url = `${getAPISemanticSearchURL()}/${query}`;
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

async function getDataSourceCategories() {
  const url = getCatsURL();
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

async function getDateRange(dataSourceIDs) {
  const url = getDateRangeURL();
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { api_ids: dataSourceIDs } })
      .then(({ data }) => { resolve(data['Date Range']); })
      .catch((err) => { reject(err); });
  });
}

async function deleteMailingListEntry(
  userID,
  mailingList,
  addressToRemove,
) {
  const url = getRecipReplaceURL();
  const newList = mailingList.filter((address) => (
    address !== addressToRemove
  ));
  return new Promise((resolve, reject) => {
    axios.post(url, {
      user_id: userID,
      new_recips: newList.join(),
    }).then(() => { resolve(addressToRemove); })
      .catch((err) => { reject(err); });
  });
}

const accessToken = APP_STYLE === SITE_VARIANTS.SFA
  ? 'EQwJ7Xu9qJAPFNJEdydATj32'
  : 'fAgn39ap5wUa4Gus4ALPT6aX';
async function getHMDAStateForm() {
  const url = getHMDAStateFormURL();
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { accessToken } })
      .then(({ data }) => { resolve(data['HMDA state form']); })
      .catch((err) => { reject(err); });
  });
}

async function getHMDAStateData(path, params) {
  const url = getHMDAStateQueryURL()
    .concat(path ? `/${path}` : '');
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { ...params, accessToken } })
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

async function getHMDAAggregateForm() {
  const url = getHMDAAggregateFormURL();
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { accessToken } })
      .then(({ data }) => { resolve(data['HMDA aggregate form']); })
      .catch((err) => { reject(err); });
  });
}

async function getHMDAAggregateData(path, params) {
  const url = getHMDAAggregateQueryURL()
    .concat(path ? `/${path}` : '');
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { ...params, accessToken } })
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

async function getHMDAAggregateFields() {
  const url = getHMDAAggregateFieldsURL();
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { accessToken } })
      .then(({ data }) => {
        resolve(data[Object.keys(data)[0]]);
      })
      .catch((err) => { reject(err); });
  });
}

async function getHMDAColumnInfo() {
  const url = getHMDAColumnInfoURL();
  return new Promise((resolve, reject) => {
    axios.get(url, { params: { accessToken } })
      .then(({ data }) => { resolve(data['HMDA fields']); })
      .catch((err) => { reject(err); });
  });
}

async function getDataSrcForm() {
  const url = getDataSrcFormURL();
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Data source form']); })
      .catch((err) => { reject(err); });
  });
}

const getCollectionInfo = async (
  name,
  resource,
  path,
  params,
) => new Promise((resolve, reject) => {
  const url = getCollectionURL(name, resource)
    .concat(path ? `/${path}` : '');
  axios.get(url, { params })
    .then(({ data }) => { resolve(data); })
    .catch((err) => { reject(err); });
});

async function getReportDestinations() {
  const url = getReportDestinationsURL();
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Report destinations']); })
      .catch((err) => { reject(err); });
  });
}

async function getUSStateReport(type, state, year) {
  const url = getUSStateReportURL(type);
  return new Promise((resolve, reject) => {
    axios.get(`${url}/${state}/${year}`)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

async function getUSStateReportFields() {
  const url = getUSStateReportFieldsURL();
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['US state yearly fields']); })
      .catch((err) => { reject(err); });
  });
}

async function getUSStateRetrieve(code) {
  const url = getUSStateRetrieveURL();
  return new Promise((resolve, reject) => {
    axios.get(`${url}/${code}`)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

async function getUSStateFields() {
  const url = getUSStateFieldsURL();
  return new Promise((resolve, reject) => {
    axios.get(`${url}`)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

export async function getAddGlossaryItemForm() {
  const url = getAddGlossaryEntryURL('RETRIEVE');
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Glossary Add Form']); })
      .catch((err) => { reject(err); });
  });
}
export async function addGlossaryItem(details) {
  const url = getAddGlossaryEntryURL('CREATE');
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function editGlossaryItem(id, details) {
  const url = `${getAddGlossaryEntryURL('UPDATE')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function deleteGlossaryItem(id, details) {
  const url = `${getAddGlossaryEntryURL('DELETE')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.delete(url, { data: details, headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

export async function getAddBibliographyItemForm() {
  const url = getAddBibliographyEntryURL('RETRIEVE');
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Bibliography Add Form']); })
      .catch((err) => { reject(err); });
  });
}
export async function addBibliographyItem(details) {
  const url = getAddBibliographyEntryURL('CREATE');
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function editBibliographyItem(id, details) {
  const url = `${getAddBibliographyEntryURL('UPDATE')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function deleteBibliographyItem(id, details) {
  const url = `${getAddBibliographyEntryURL('DELETE')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.delete(url, { data: details, headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

export async function getAddGrossIssuanceItemForm() {
  const url = getURL('GrossIssuanceForm');
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['GrossIssuance Add Form']); })
      .catch((err) => { reject(err); });
  });
}
export async function addGrossIssuanceItem(details) {
  const url = getURL('GrossIssuanceAdd');
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function editGrossIssuanceItem(id, details) {
  const url = `${getURL('GrossIssuanceEdit')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function deleteGrossIssuanceItem(id, details) {
  const url = `${getURL('GrossIssuanceDelete')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.delete(url, { data: details, headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

export async function getAddServicerPerformanceItemForm() {
  const url = getURL('ServicerPerfomanceForm');
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['ServicerPerformance Add Form']); })
      .catch((err) => { reject(err); });
  });
}
export async function addServicerPerformanceItem(details) {
  const url = getURL('ServicerPerfomanceAdd');
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function editServicerPerformanceItem(id, details) {
  const url = `${getURL('ServicerPerfomanceEdit')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function deleteServicerPerformanceItem(id, details) {
  const url = `${getURL('ServicerPerfomanceDelete')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.delete(url, { data: details, headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

export async function getAddJournalPeopleItemForm() {
  const url = getAddJournalPeopleEntryURL('RETRIEVE');
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['People Add Form']); })
      .catch((err) => { reject(err); });
  });
}
export async function addJournalPeopleItem(details) {
  const url = getAddJournalPeopleEntryURL('CREATE');
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function getJournalPeopleRead(role, name, userId) {
  return new Promise((resolve, reject) => {
    let url = getJournalPeopleReadURL();
    if (role || name || userId) { url += '?'; }
    if (name) { url += `name=${name}`; }
    if (role) { url += `role=${role}`; }
    axios.get(url)
      .then(({ data }) => { resolve(getProperty(data)); })
      .catch(reject);
  });
}
export async function editJournalPeopleItem(id, details) {
  const url = `${getAddJournalPeopleEntryURL('UPDATE')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.put(url, details)
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}
export async function deleteJournalPeopleItem(id, details) {
  const url = `${getAddJournalPeopleEntryURL('DELETE')}/${id}`;
  return new Promise((resolve, reject) => {
    axios.delete(url, { data: details, headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => { resolve(data); })
      .catch((err) => { reject(err); });
  });
}

export async function getSecurityPolicy(page) {
  const url = `${getSecurityManagerURL()}/${page}`;
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => { resolve(data['Security Manager'][page]); })
      .catch(reject);
  });
}

export function getIsValidUser(protocol, action, user) {
  if (typeof protocol !== 'string' || !protocol) {
    console.error(`Invalid protocol: ${protocol}`);
    return Promise.resolve(false);
  }
  const VALID_ACTIONS = ['create', 'read', 'update', 'delete'];
  if (!VALID_ACTIONS.includes(action)) {
    console.error(`Invalid action: ${action}`);
    return Promise.resolve(false);
  }
  if (!user) {
    console.error(`Invalid user: ${user}`);
    return Promise.resolve(false);
  }

  const endpoint = getIsValidUserURL();
  const url = `${endpoint}/${protocol}/${action}/${user}`;
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => resolve(data.isValid))
      .catch(reject);
  });
}

export function editJournalText(title, text) {
  const endpointURL = getJournalTextURL('UPDATE');
  const url = `${endpointURL}/${title}`;
  return new Promise((resolve, reject) => {
    axios.put(url, { text })
      .then(resolve)
      .catch(reject);
  });
}
export function getJournalText({ resource = 'MAP', specifiedItem }) {
  const url = getJournalTextURL(resource);
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(({ data }) => {
        const [key] = Object.keys(data);
        if (specifiedItem) { resolve(data[key][specifiedItem]); return; }
        resolve(data[key]);
      })
      .catch(reject);
  });
}

export function getJournalSubmissionForm() {
  return new Promise((resolve, reject) => {
    axios.get(getJournalSubmissionFormURL())
      .then(({ data }) => { resolve(data['Journal manuscript form']); })
      .catch(reject);
  });
}

export function submitJournalManuscript(userResponses) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(userResponses).forEach((key) => {
      if (key === 'authors') {
        formData.append(key, JSON.stringify(userResponses[key].value));
      } else {
        formData.append(key, userResponses[key].value);
      }
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.put(getJournalSubmissionURL(), formData, config)
      .then(({ data }) => { resolve(data); })
      .catch(reject);
  });
}

export function doManuAction(id, actionDict) {
  return new Promise((resolve, reject) => {
    axios.put(`${getJournalManuscriptsActionDoURL()}/${id}`, actionDict)
      .then(({ data }) => { resolve(data); })
      .catch(reject);
  });
}

export function getManuActions() {
  return new Promise((resolve, reject) => {
    axios.get(getJournalManuscriptsActionReadURL())
      .then(({ data }) => { resolve(getProperty(data)); })
      .catch(reject);
  });
}

export function getJournalManuscriptsMap() {
  return new Promise((resolve, reject) => {
    axios.get(getJournalManuscriptsMapURL())
      .then(({ data }) => { resolve(getProperty(data)); })
      .catch(reject);
  });
}

export function getJournalDashColumns() {
  return new Promise((resolve, reject) => {
    axios.get(getJournalDashColumnsURL())
      .then(({ data }) => { resolve(data); })
      .catch(reject);
  });
}

export function getJournalStatesRead() {
  return new Promise((resolve, reject) => {
    axios.get(getJournalStatesReadURL())
      .then(({ data }) => { resolve(getProperty(data)); })
      .catch(reject);
  });
}

export function getJournalMasthead() {
  return new Promise((resolve, reject) => {
    axios.get(getJournalMastheadURL())
      .then(({ data }) => { resolve(data.masthead); })
      .catch(reject);
  });
}

export function get(endpoint) {
  return new Promise((resolve, reject) => {
    axios.get(getURL(endpoint))
      .then(({ data }) => { resolve(getProperty(data)); })
      .catch(reject);
  });
}

export {
  URL_MAP,
  getURL,
  checkAuth,
  deleteReport,
  fetchReportList,
  fetchAPIs,
  canEditReport,
  getAddDataSourceTypes,
  getAddDataSourceQuestions,
  addDataSource,
  APISemanticSearch,
  getFrequencyOptions,
  getDataSourceCategories,
  getDateRange,
  deleteMailingListEntry,
  getHMDAStateForm,
  getHMDAStateData,
  getHMDAAggregateForm,
  getHMDAAggregateData,
  getHMDAAggregateFields,
  getHMDAColumnInfo,
  getCollectionInfo,
  getDataSrcForm,
  getReportDestinations,
  getDataSourceFields,
  getUSStateReport,
  getUSStateReportFields,
  getUSStateRetrieve,
  getUSStateFields,
};
