import React, { useContext, useEffect, useState } from 'react';

import {
  requiredValuesMissing,
  getErrorMessage,
  formatQuestions,
} from '../../../../core/Utils/objectUtils';

import PageTitle from '../../../../core/Display/PageTitle';
import Dialog from '../../../../core/Display/Dialog';
import LoadingAnimation from '../../../../core/Display/LoadingAnimation';

import Questions from '../../../../core/Input/Questions';
import Button from '../../../../core/Input/Button';
import ButtonGroup from '../../../../core/Input/ButtonGroup';
import { CheckboxIcon } from '../../../../core/Input/Checkbox';

import {
  getJournalSubmissionForm,
  submitJournalManuscript,
} from '../../../../utils/networkUtils';
import UserContext from '../../../../UserContext';

import EditableText from '../EditableText';

import './Submissions.css';

/* eslint jsx-a11y/label-has-associated-control: off */
/*
 * This is turned off because it seems to be bugged under the airbnb styleguide.
 * There are 2 ways (normally) to satisfy this:
 * - have an input as a child of a label
 * - have the `htmlFor` prop on the label and the `id` prop on the input be the same value
 * both are true for the checkbox.
 *
 * I don't know why this is giving me a problem.
 *
 * - Sam
 */

const AFFIRMATION_CHECKBOX_ID = 'affirmation-checkbox';
const SUBMISSION_FORM_ID = 'submission-form';
const DEFAULT = 0;
const PENDING = 1;
const SUCCESS = 2;
const ERROR = 3;

function getStatusTitle(status) {
  if (status === SUCCESS) return 'Success';
  if (status === ERROR) return 'Error';
  return '';
}

function Form() {
  const { receivedURLs } = useContext(UserContext);
  const [questions, setQuestions] = useState();
  const [affirmed, setAffirmed] = useState(false);
  const [status, setStatus] = useState(DEFAULT);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');

  const responseSetter = (field, value) => {
    setQuestions({
      ...questions,
      [field]: { ...questions[field], value },
    });
  };

  useEffect(() => {
    if (!receivedURLs) return;
    getJournalSubmissionForm()
      .then((data) => { setQuestions(formatQuestions(data)); });
  }, [receivedURLs]);

  if (!questions) return null;
  return (
    <>
      {status !== DEFAULT && (
        <Dialog
          title={getStatusTitle(status)}
          closeFn={status === PENDING ? undefined : () => { setStatus(DEFAULT); }}
        >
          {status === PENDING && <LoadingAnimation />}
          {status === ERROR && errorMessage}
          {status === SUCCESS && 'Your manuscript was successfully submitted.'}
        </Dialog>
      )}
      <div className="submission-form-wrapper">
        <Questions
          questions={questions}
          responseSetter={responseSetter}
          formID={SUBMISSION_FORM_ID}
        />
        <label
          className="checkbox-label affirmation-label"
          htmlFor={AFFIRMATION_CHECKBOX_ID}
        >
          <input
            className="checkbox"
            type="checkbox"
            id={AFFIRMATION_CHECKBOX_ID}
            value={affirmed}
            onChange={() => { setAffirmed(!affirmed); }}
            style={{ display: 'none' }}
          />
          <CheckboxIcon checked={affirmed} />
          <EditableText specifiedItem="Affirmation" noTitle />
        </label>
        <ButtonGroup>
          <Button
            type="submit"
            form={SUBMISSION_FORM_ID}
            onClick={() => {
              setStatus(PENDING);
              submitJournalManuscript(questions)
                .then(() => { setStatus(SUCCESS); })
                .catch((response) => {
                  setErrorMessage(getErrorMessage(response));
                  setStatus(ERROR);
                });
            }}
            disabled={!affirmed || requiredValuesMissing(questions)}
          >
            Submit
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
}

export default function Submissions() {
  return (
    <div className="submissions">
      <PageTitle text="Submissions" />
      <EditableText className="specifications" specifiedItem="Submission Guidelines" />
      {false && <span className="divider" />}
      <Form />
    </div>
  );
}
